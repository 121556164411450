.widget-tab {
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
}

.widget-defintion {
  overflow-x: hidden;
}
.widget-tab .section-title {
  text-align: center;
}

.saved-widgets-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 1rem 0 3rem;
  align-items: center;
}

.add-widget-btn {
  background-color: "#fff";
  cursor: "pointer";
}

.widget-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iframe-section {
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex: 1;
  gap: 25px;
  margin-top: 5rem;
}

.iframe-code {
  background-color: #e0e1e2;
  padding: 1rem;
  height: 7rem;
  border-radius: 8px;
  text-wrap: wrap;
}
.iframe-copy-btn {
  border-radius: 20px;
  color: var(--primary-color);
  border: var(--primary-color);
  background-color: var(--background-color-1);
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 40px;
  font-size: 16px;
  align-self: flex-end;
}
.iframe-copy-btn:hover,
.iframe-copy-btn:focus {
  color: white;
  background-color: var(--primary-color);
  cursor: pointer;
}
.iframe-copy-btn:disabled {
  color: var(--primary-color);
  border: var(--primary-color);
  background-color: var(--background-color-1);
  cursor: default;
}

.widget-config {
  gap: 2rem;
}
.widget-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.widget-style .section-subtitle {
  margin-top: 1rem;
}

.extra-infos-section {
  display: grid;
  grid-row-gap: 1rem;
  color: var(--text-color);
  font-size: 18px;
}

.horizontal-info-group {
  display: flex;
  align-items: center;
  gap: 10px;
}
